<template>
  <nav class="navigation">
    <ul>
      <li
        v-for="(item, index) in menu"
        :key="index">
        <router-link :to="item.to">
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    menu: {
      type: Array,
      required: true
    }
  }
}
</script>
