<template>
  <nav id="nav">
    <div class="page-info">
      <div class="title">
        <h1>{{ title }}</h1>
        <h2>{{ subtitle }}</h2>
      </div>

      <el-breadcrumb separator="/">
        <template v-for="breadcrumb, index in breadcrumbs">
          <el-breadcrumb-item 
            v-if="breadcrumb.to"
            :key="index"
            :to="breadcrumb.to">
            {{ breadcrumb.text }}
          </el-breadcrumb-item>

          <el-breadcrumb-item
            v-else
            :key="index">
            {{ breadcrumb.text }}
          </el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div>

    <div class="actions">
      <portal-target name="actions" />
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    title () {
      return this.$store.state.page.title
    },

    subtitle () {
      return this.$store.state.page.subtitle
    },

    breadcrumbs () {
      return this.$store.state.page.breadcrumbs
    }
  }
}
</script>
