<template>
  <div>
    <panel title="Trades">
      <trades-table :trades="trades" />
    </panel>
  </div>
</template>

<script>
import store from '../../../store'

import TradesTable from './TradesTable';

export default {
  async beforeRouteEnter (to, from, next) {
    const promises = [
      store.dispatch('trades/load'),
    ]
    try {
      await Promise.all(promises)
      next()
    } catch {
      next({ name: 'Customers' })
    }
  },

  components: {
    TradesTable
  },

  computed: {
    trades () {
      return this.$store.getters['trades/list']
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Trades',
      breadcrumbs: [
        {
          text: 'Listings'
        },
        {
          text: 'Trades'
        }
      ]
    })
  }
}
</script>