<template>
  <form-item
    :validator="validator"
    class="w-full">
    <el-checkbox
      v-if="!showAsText"
      v-model="val"
      v-bind="options"
      :placeholder="placeholder"
      :disabled="disabled">
      <slot />
    </el-checkbox>
    <span
      v-else
      class="textual">
      {{ val || 'N/A' }}
    </span>
  </form-item>
</template>

<script>
import inputMixin from '../../input-mixin.js'

export default {
  mixins: [inputMixin]
}
</script>

<style lang="scss" scoped>
  .textual {
    display: block;
    min-height: 20px;
    line-height: 20px;
    padding: 10px 0;
    font-weight: bold;
  }
</style>