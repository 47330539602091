<template>
  <div>
    <portal to="actions">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="manage = true">
        Add new System Message
      </el-button>
    </portal>

    <panel title="System Messages">
      <el-table
        :data="messages"
        style="width: 100%">
        <el-table-column label="Message">
          <template slot-scope="scope">
            {{ scope.row.message }}
          </template>
        </el-table-column>

        <el-table-column
          label="Date/Time"
          width="250">
          <template slot-scope="scope">
            {{ scope.row.createdAt | date }}
          </template>
        </el-table-column>

        <el-table-column
          label="By"
          width="250">
          <template slot-scope="scope">
            {{ scope.row.createdBy.firstName }} {{ scope.row.createdBy.lastName }}
          </template>
        </el-table-column>

        <el-table-column
          label="Actions"
          width="100"
          align="right">
          <template slot-scope="scope">
            <el-tooltip
              placement="top"
              content="Edit">
              <el-button
                size="mini"
                type="default"
                icon="el-icon-edit"
                circle
                @click="openEdit(scope.row)" />
            </el-tooltip>

            <el-tooltip
              placement="top"
              content="Delete">
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="remove(scope.row.id)" />
            </el-tooltip>

          </template>
        </el-table-column>
      </el-table>
    </panel>

    <gn-drawer
      v-model="manage"
      title="Add new System Message"
      :before-close="beforeClose"
      @closed="onClose()">
      <el-form label-position="top">
        <gn-textarea
          v-model="form.message"
          :validator="$v.form.message"
          label="Message"
          placeholder="Enter message" />
      </el-form>

      <template #footer>
        <el-button
          class="button"
          @click="manage = false">
          Cancel
        </el-button>
        <el-button
          class="button"
          type="primary"
          :loading="loading"
          @click="save()">
          {{ loading ? 'Saving...' : 'Save' }}
        </el-button>
      </template>
    </gn-drawer>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      form: {
        message: null
      },
      manage: false,
      editing: null,
      loading: false
    }
  },

  computed: {
    messages () {
      const messages = this.$store.getters['systemMessage/list'] || []
      return messages.sort((a, b) => {
        const aa = parseInt(a.id)
        const bb = parseInt(b.id)
        if (aa > bb) return -1
        if (aa < bb) return 1
        return 0
      })
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'System Messages',
      breadcrumbs: [
        {
          text: 'Tools'
        },
        {
          text: 'System Messages'
        }
      ]
    })
  },

  methods: {
    async save () {
      this.loading = true
      try {
        let payload = {
          ...this.form,
          createdAt: 'CURRENT_TIMESTAMP',
          createdBy: window.appData.currentUser.id
        }
        if (this.editing) {
          payload = {
            id: this.editing.id,
            payload: {
              message: this.form.message,
              updatedAt: 'CURRENT_TIMESTAMP'
            }
          }

          const readed = this.$store.getters['readed/list'] || []
          await Promise.all(readed.filter(item => item.itemType === 'SYSTEM_MESSAGES' && item.itemId === this.editing.id)
            .map(read => this.$store.dispatch('readed/delete', read.id)))

          const discarded = this.$store.getters['discarded/list'] || []
          await Promise.all(discarded.filter(item => item.itemType === 'SYSTEM_MESSAGES' && item.itemId === this.editing.id)
            .map(discard => this.$store.dispatch('discarded/delete', discard.id)))
        }

        await this.$store.dispatch(`systemMessage/${this.editing ? 'update' : 'create'}`, payload)

        this.$message.success(`System Message successfully ${this.editing ? 'edited' : 'created'}.`)
        this.onClose()
      } catch (e) {
        console.error(e)
      }
    },

    async remove(id) {
      try {
        await this.$confirm('Are you sure to delete System Message?')
        await this.$store.dispatch('systemMessage/delete', id)
      } catch (e) {
        console.error(e)
      }
    },

    openEdit (message) {
      this.editing = message
      this.form.message = message.message
      this.manage = true
    },

    async beforeClose (done) {
      if (!this.form.message) return done()
      try {
        await this.$confirm('Are you sure you want to close this?')
        done()
      } catch (e) {
        console.error(e)
      }
    },

    onClose () {
      this.loading = false
      this.manage = false
      this.$v.form.$reset()
      this.editing = null
      this.form.message = null
    }
  },

  validations: {
    form: {
      message: { required }
    }
  }
}
</script>
