<template>
  <div>
    <portal to="actions">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="manage = true">
        Add new Post
      </el-button>

      <el-button
        type="default"
        icon="el-icon-s-operation"
        @click="openFilters = true">
        Filters
      </el-button>
    </portal>

    <div class="applied-filters">
      <el-tag
        v-for="filter in appliedFilters"
        :key="filter.key"
        type="primary"
        effect="dark"
        class="applied-filter"
        size="small"
        closable
        @close="removeFilter(filter)">
        {{ filter.text }}
      </el-tag>
    </div>

    <panel title="Posts">
      <posts-table :posts="filteredPosts" />
    </panel>

    <manage-post
      v-if="manage"
      @close="closeManage()" />

    <posts-filters
      v-if="openFilters"
      :filters="filters"
      @apply="applyFilters($event)"
      @close="openFilters = false" />
  </div>
</template>

<script>
import moment from 'moment'
import store from '../../../store'

import ManagePost from './ManagePost'
import PostsTable from './PostsTable'
import PostsFilters from './PostsFilters'

export default {
  async beforeRouteEnter (to, from, next) {
    const promises = [
      store.dispatch('posts/load'),
      store.dispatch('users/load')
    ]
    await Promise.all(promises)
    next()
  },

  components: {
    PostsTable,
    ManagePost,
    PostsFilters
  },

  data () {
    return {
      manage: false,
      filters: {
        status: 'ACTIVE'
      },
      openFilters: false
    }
  },

  computed: {
    posts () {
      const expiringSoon = this.$store.getters['posts/list'].filter(post => moment(post.expiringAt) >= moment()).sort((a, b) => moment(a.expiringAt) - moment(b.expiringAt))
      const expired = this.$store.getters['posts/list'].filter(post => moment(post.expiringAt) < moment()).sort((a, b) => moment(b.expiringAt) - moment(a.expiringAt))
      return [...expiringSoon, ...expired]
    },

    appliedFilters () {
      const filterNames = {
        classification: 'Post Classification',
        profileId: 'Profile ID',
        status: 'Status',
        autoPublished: 'Auto-Published',
        reviewedAt: 'Reviewed At',
        expiringAt: 'Expiring At',
        createdAt: 'Created At',
        awaitingReview: 'Awaiting Review',
      }
      
      if (!this.filters) return []

      return Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key])).map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))
    },

    filteredPosts () {
      let posts = [ ...this.posts ]
      if (!this.filters) return posts.filter(post => ['ACTIVE', 'VERIFIED'].includes(post.user.status))

      const dateFilters = ['reviewedAt', 'expiringAt', 'createdAt']

      Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key])).forEach(key => {
        if (['autoPublished', 'classification'].includes(key)) {
          posts = posts.filter(post => post[key] === this.filters[key])
        }

        if (key === 'awaitingReview') {
          posts = posts.filter(post => !post.reviewedAt === this.filters[key])
        }

        if (key === 'profileId') {
          posts = posts.filter(post => post.user.uuid.includes(this.filters[key]))
        }

        if (key === 'status') {
          posts = posts.filter(post => post.status === this.filters[key])
        }

        if (dateFilters.includes(key)) {
          posts = posts.filter(post => {
            const date = moment(post[key]).format('YYYY-MM-DD')
            const start =  moment(this.filters[key][0]).format('YYYY-MM-DD')
            const end =  moment(this.filters[key][1]).format('YYYY-MM-DD')
            return moment(date) >=  moment(start) && moment(date) <= moment(end)
          })
        }
      })

      return posts
    },

    user () {
      return this.$store.state.user.user
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Posts',
      breadcrumbs: [
        {
          text: 'Listings'
        },
        {
          text: 'Posts'
        }
      ]
    })
  },

  methods: {
    getFilterValue (key, value) {
      if (key === 'awaitingReview') {
        return value ? 'Yes' : 'No'
      }

      if (key === 'classification') {
        return window.classifications[value]
      }

      if (key === 'status') {
        return window.postStatuses[value]
      }

      const dateFilters = ['reviewedAt', 'expiringAt', 'createdAt']
      if (dateFilters.includes(key)) {
        const start = moment(value[0]).format('LL')
        const end = moment(value[1]).format('LL')
        return `${start} - ${end}`
      }

      if (key === 'autoPublished') return value ? 'Yes' : 'No'

      return value
    },

    removeFilter (filter) {
      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openFilters = false
    },

    closeManage () {
      this.manage = false
    }
  }
}
</script>
