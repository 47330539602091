<template>
  <div>
    <portal to="actions">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="manage = true">
        Add new Offer
      </el-button>

      <el-button
        type="default"
        icon="el-icon-s-operation"
        @click="openFilters = true">
        Filters
      </el-button>
    </portal>

    <div class="flex-between mb-20">
      <div class="applied-filters">
        <el-tag
          v-for="filter in appliedFilters"
          :key="filter.key"
          type="primary"
          effect="dark"
          class="applied-filter"
          size="small"
          closable
          @close="removeFilter(filter)">
          {{ filter.text }}
        </el-tag>
      </div>
      <el-button-group>
        <el-button
          size="small"
          :type="savedOffers ? 'default' : 'primary'"
          :disabled="!savedOffers"
          @click="savedOffers = false">
          Pinned
        </el-button>
        <el-button
          size="small"
          :type="savedOffers ? 'primary' : 'default'"
          :disabled="savedOffers"
          @click="savedOffers = true">
          Saved
        </el-button>
      </el-button-group>
    </div>

    <panel :title="(!savedOffers ? 'Pinned' : 'Saved') + ' Offers'">
      <offers-table :offers="filteredOffers" />
    </panel>

    <manage-offer
      v-if="manage"
      @close="closeManage()" />

    <offers-filters
      v-if="openFilters"
      :filters="filters"
      :saved-offers="savedOffers"
      @apply="applyFilters($event)"
      @close="openFilters = false" />
  </div>
</template>

<script>
import moment from 'moment'
import store from '../../../store'

import ManageOffer from './ManageOffer'
import OffersTable from './OffersTable'
import OffersFilters from './OffersFilters'

export default {
  async beforeRouteEnter (to, from, next) {
    const promises = [
      store.dispatch('offers/load'),
      store.dispatch('users/load')
    ]
    await Promise.all(promises)
    next()
  },

  components: {
    OffersTable,
    ManageOffer,
    OffersFilters
  },

  data () {
    return {
      manage: false,
      filters: {
        status: 'ACTIVE'
      },
      savedOffers: false,
      openFilters: false
    }
  },

  computed: {
    offers () {
      const offers = (this.$store.getters['offers/list'] || []).filter(offer => this.savedOffers ? offer.saved : offer.postOffers.length)
      if (this.savedOffers) return offers
      
      const array = []
      offers.forEach(offer => {
        offer.postOffers.forEach(postOffer => {
          array.push({
            ...offer,
            postOffer
          })
        })
      })

      return array
    },

    appliedFilters () {
      const filterNames = {
        classification: 'Offer Classification',
        profileId: 'Profile ID',
        status: 'Status',
        autoPublished: 'Auto-Published',
        reviewedAt: 'Reviewed At',
        createdAt: 'Created At',
        awaitingReview: 'Awaiting Review'
      }
      
      if (!this.filters) return []

      return Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key])).map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))
    },

    filteredOffers () {
      let offers = [ ...this.offers ]
      if (!this.filters) return offers.filter(offer => ['ACTIVE', 'VERIFIED'].includes(offer.user.status))

      const dateFilters = ['reviewedAt', 'createdAt']

      Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key])).forEach(key => {
       if (['autoPublished', 'classification'].includes(key)) {
          offers = offers.filter(offer => offer[key] === this.filters[key])
        }

        if (key === 'awaitingReview') {
          offers = offers.filter(offer => !offer.reviewedAt === this.filters[key])
        }

        if (key === 'profileId') {
          offers = offers.filter(offer => offer.user.uuid.includes(this.filters[key]))
        }

        if (key === 'status') {
          offers = offers.filter(offer => (this.savedOffers ? offer.status : offer.postOffer.status) === this.filters[key])
        }

        if (dateFilters.includes(key)) {
          offers = offers.filter(offer => {
            const date = moment(offer[key]).format('YYYY-MM-DD')
            const start =  moment(this.filters[key][0]).format('YYYY-MM-DD')
            const end =  moment(this.filters[key][1]).format('YYYY-MM-DD')
            return moment(date) >=  moment(start) && moment(date) <= moment(end)
          })
        }
      })

      return offers
    },

    user () {
      return this.$store.state.user.user
    }
  },

  watch: {
    savedOffers () {
      this.resetFilters()
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Offers',
      breadcrumbs: [
        {
          text: 'Listings'
        },
        {
          text: 'Offers'
        }
      ]
    })
  },

  methods: {
    resetFilters () {
      this.filters = {
        status: 'ACTIVE'
      }
    },

    getFilterValue (key, value) {
      if (key === 'awaitingReview') {
        return value ? 'Yes' : 'No'
      }

      if (key === 'classification') {
        return window.classifications[value]
      }

      if (key === 'status') {
        return this.savedOffers ? window.offerStatuses[value] : window.postOfferStatuses[value]
      }

      const dateFilters = ['reviewedAt', 'createdAt']
      if (dateFilters.includes(key)) {
        const start = moment(value[0]).format('LL')
        const end = moment(value[1]).format('LL')
        return `${start} - ${end}`
      }

      if (key === 'autoPublished') return value ? 'Yes' : 'No'

      return value
    },

    removeFilter (filter) {
      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openFilters = false
    },

    closeManage () {
      this.manage = false
    }
  }
}
</script>

<style lang="scss" scoped>
.applied-filters {
  margin: 0;
}
</style>
