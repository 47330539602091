<template>
  <gn-drawer
    :value="true"
    title="Add Profile Points"
    @closed="() => $emit('close')">
    <el-form label-position="top">
      <gn-select
        v-if="!userId"
        v-model="form.userId"
        :options="profileOptions"
        :validator="$v.form.userId"
        label="Profile"
        placeholder="Select Profile"
        filterable />

      <gn-input
        v-model="form.text"
        :validator="$v.form.text"
        maxlength="50"
        label="Text"
        placeholder="Enter Text" />

      <gn-input
        v-model.number="form.points"
        :validator="$v.form.points"
        label="Points"
        placeholder="Enter Points" />
    </el-form>

    <template #footer>
      <el-button
        class="button"
        @click="$emit('close')">
        Cancel
      </el-button>
      <el-button
        class="button"
        type="primary"
        :loading="loading"
        @click="save()">
        Save
      </el-button>
    </template>
  </gn-drawer>
</template>

<script>
import { required, maxLength, integer } from 'vuelidate/lib/validators'

export default {
  props: {
    userId: {
      type: Number,
      default: null
    },
  },

  data () {
    return {
      form: {
        text: null,
        userId: null,
        points: null
      },

      loading: false
    }
  },

  computed: {
    profileOptions () {
      return this.$store.getters['users/list'].filter(user => !user.admin).map(user => ({
        id: user.id,
        text: `${user.uuid} - ${user.firstName} ${user.lastName}`
      }))
    }
  },

  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.loading = true
      try {
        const points = await this.$store.dispatch('profilePoints/create', {
          ...this.form,
          userId: this.userId || this.form.userId,
          createdAt: 'CURRENT_TIMESTAMP',
          createdBy: window.appData.currentUser.id
        })
        this.$message.success(`Profile points added successfully.`)
        this.$emit('add', points)
      } catch {

      } finally {
        this.loading = false
      }
    }
  },

  validations () {
    const rules = {
      form: {
        text: {
          required,
          maxLength: maxLength(50)
        },
        points: {
          required,
          integer
        }
      }
    }

    if (!this.userId) {
      rules.form.userId = { required }
    }

    return rules
  }
}
</script>