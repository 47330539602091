<template>
  <div>
    <portal to="actions">
      <el-button
        type="default"
        icon="el-icon-s-operation"
        @click="openFilters = true">
        Filters
      </el-button>
    </portal>

    <navigation :menu="menu" />

    <div class="applied-filters">
      <el-tag
        v-for="filter in appliedFilters"
        :key="filter.key"
        type="primary"
        effect="dark"
        class="applied-filter"
        size="small"
        closable
        @close="removeFilter(filter)">
        {{ filter.text }}
      </el-tag>
    </div>

    <panel title="Customers">
      <el-table
        :data="filteredCustomers">
        <el-table-column
          label="Profile ID"
          width="100">
          <template slot-scope="scope">
            <router-link :to="{ name: 'CustomerView', params: { id: scope.row.id } }">
              {{ scope.row.uuid }}
            </router-link>
          </template>
        </el-table-column>

        <el-table-column label="Created At">
          <template slot-scope="scope">
            {{ scope.row.createdAt | date }}
          </template>
        </el-table-column>

        <el-table-column label="First Name">
          <template slot-scope="scope">
            {{ scope.row.firstName }}
            <el-tooltip
              v-if="scope.row.status === 'VERIFIED'"
              content="Verified"
              placement="top">
              <span class="verified-profile-badge">
                <span class="el-icon-check" />
              </span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="Last Name">
          <template slot-scope="scope">
            {{ scope.row.lastName }}
          </template>
        </el-table-column>

        <el-table-column label="Email">
          <template slot-scope="scope">
            {{ scope.row.email }}
          </template>
        </el-table-column>

        <el-table-column label="Verified">
          <template slot-scope="scope">
            <el-tooltip
              v-if="verificationStatus(scope.row)"
              :content="verificationStatus(scope.row)"
              placement="top">
              <span
                class="status-icon"
                :class="verificationIcon(scope.row)" />
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column
          label="Status"
          width="200"
          align="right">
          <template slot-scope="scope">
            <el-tag
              :type="statusColor[scope.row.status]"
              effect="dark"
              size="mini">
              {{ scope.row.status | status }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </panel>

    <customers-filters
      v-if="openFilters"
      :filters="filters"
      @apply="applyFilters($event)"
      @close="openFilters = false" />
  </div>
</template>

<script>
import moment from 'moment'
import store from '../../store'

import CustomersFilters from './CustomersFilters'
import Navigation from '../common/ui/Navigation'

export default {
  async beforeRouteEnter (to, from, next) {
    await store.dispatch('users/load')
    next()
  },

  components: {
    Navigation,
    CustomersFilters
  },

  data () {
    return {
      statusColor: window.userStatusColors,
      filters: null,
      openFilters: false,

      menu: [
        {
          name: 'Profiles',
          to: { name: 'Customers' }
        }
      ]
    }
  },

  computed: {
    appliedFilters () {
      const filterNames = {
        verified: 'Verified',
        uuid: 'Profile ID',
        status: 'Status',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        createdAt: 'Created At'
      }
      
      if (!this.filters) return []

      return Object.keys(this.filters).filter(key => this.filters[key]).map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))
    },

    customers () {
      return this.$store.getters['users/list'].filter(user => !user.admin)
    },

    filteredCustomers () {
      let customers = [ ...this.customers ]
      if (!this.filters) return customers

      const textualFilters = ['firstName', 'lastName', 'email', 'uuid']

      Object.keys(this.filters).filter(key => this.filters[key]).forEach(key => {
        if (textualFilters.includes(key)) {
          customers = customers.filter(customer => customer[key].includes(this.filters[key]))
        }

        if (key === 'verified') {
          if (this.filters[key] === 'VERIFIED') {
            customers = customers.filter(customer => customer.status === 'VERIFIED')
          }
          // if (this.filters[key] === 'NO') {
          //   customers = customers.filter(customer => customer.status !== 'VERIFIED')
          // }
          if (this.filters[key] === 'IN_VERIFICATION') {
            customers = customers.filter(customer => customer.inVerification && !customer.disapprovalReason)
          }
          if (this.filters[key] === 'REJECTED') {
            customers = customers.filter(customer => customer.inVerification && customer.disapprovalReason)
          }
        }

        if (key === 'status') {
          customers = customers.filter(customer => customer.status === this.filters[key])
        }

        if (key === 'createdAt') {
          customers = customers.filter(customer => {
            const createdAt = moment(customer.createdAt).format('YYYY-MM-DD')
            const start =  moment(this.filters[key][0]).format('YYYY-MM-DD')
            const end =  moment(this.filters[key][1]).format('YYYY-MM-DD')
            return moment(createdAt) >=  moment(start) && moment(createdAt) <= moment(end)
          })
        }
      })

      return customers
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Customers',
      breadcrumbs: [
        {
          text: 'Customers'
        }
      ]
    })
  },

  methods: {
    verificationIcon (customer) {
      if (customer.status === 'VERIFIED') return 'el-icon-success'
      if (customer.inVerification && customer.disapprovalReason) return 'el-icon-error'
      if (customer.inVerification) return 'el-icon-warning'
      return null
    },

    verificationStatus (customer) {
      if (customer.status === 'VERIFIED') return 'Verified'
      if (customer.inVerification && customer.disapprovalReason) return 'Rejected'
      if (customer.inVerification) return 'In Verification'
      return null
    },

    getFilterValue (key, value) {
      if (key === 'verified') {
        return window.customersVerified[value]
      }

      if (key === 'status') {
        return window.userStatuses[value]
      }

      if (key === 'createdAt') {
        const start = moment(value[0]).format('LL')
        const end = moment(value[1]).format('LL')
        return `${start} - ${end}`
      }

      return value
    },

    removeFilter (filter) {
      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openFilters = false
    }
  }
}
</script>

<style lang="scss" scoped>
.status-icon {
  font-size: 23px;
  &.el-icon-success {
    color: #67c23a;
  }
  &.el-icon-warning {
    color: #e6a23c;
  }
  &.el-icon-error {
    color: #f56c6c;
  }
}
</style>
