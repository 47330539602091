<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6">
        <documents
          v-if="documents.length && canSee"
          :documents="documents" />
      </el-col>
      <el-col :span="18">
        <profile-detailed-info :customer="customer" />

        <panel v-if="user.admin && customer.inVerification">
          <div class="items-center">
            <el-popconfirm
              confirm-button-text='Yes'
              cancel-button-text='No'
              title="Are you sure you want to approve this profile?"
              @confirm="approve()">
              <el-button
                slot="reference"
                type="success"
                size="small"
                icon="el-icon-check">
                Approve
              </el-button>
            </el-popconfirm>
            <el-button
              class="ml-10"
              type="danger"
              size="small"
              icon="el-icon-close"
              @click="beforeDisapprove()">
              Reject
            </el-button>
          </div>
        </panel>
      </el-col>
    </el-row>

    <gn-drawer
      v-model="disapprovalReasonDrawer"
      title="Reject Profile"
      :size="500"
      :before-close="() => (disapprovalReasonDrawer = false)"
      @closed="disapprovalReasonDrawer = false">
      <el-form label-position="top">
        <gn-textarea
          v-model="disapprovalReason"
          :validator="$v.disapprovalReason"
          maxlength="1000"
          label="Rejecting reason"
          placeholder="Enter Rejecting reason" />
      </el-form>

      <template #footer>
        <el-button
          class="button"
          :disabled="loading.verificationButtons"
          @click="disapprovalReasonDrawer = false">
          Cancel
        </el-button>
        <el-button
          class="button"
          type="danger"
          :loading="loading.verificationButtons"
          @click="disapprove()">
          Reject
        </el-button>
      </template>
    </gn-drawer>
  </div>
</template>

<script>
import store from '../../store'
import { required } from 'vuelidate/lib/validators'

import Documents from '../common/ui/Documents'
import ProfileDetailedInfo from './ProfileDetailedInfo'

export default {
  async beforeRouteEnter (to, from, next) {
    try {
      const customer = await store.dispatch('users/get', to.params.id)
      
      if (customer.inVerification) {
        next()
      } else {
        next({ name: 'CustomerProfile', params: { id: to.params.id } })
      }
    } catch {
      next({ name: 'Customers' })
    }
  },

  components: {
    Documents,
    ProfileDetailedInfo
  },

  data () {
    return {
      disapprovalReason: false,
      disapprovalReasonDrawer: false,
      loading: {
        verificationButtons: false
      }
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },

    customerId () {
      return this.$route.params.id
    },
  
    customer () {
      return this.$store.getters['users/get'](this.customerId)
    },

    canSee () {
      return this.user.admin || this.user.id === this.customerId
    },

    documents () {
      return this.customer.identifications?.map(identification => ({
        id: identification.id,
        name: identification.document,
        info: window.idTypes[identification.type],
        url: `/files/users/${identification.userId}/${identification.document}`
      })) || []
    }
  },

  created () {
    this.setPageTitle()
  },

  methods: {
    async approve () {
      this.loading.verificationButtons = true
      try {
        await store.dispatch('users/update', {
          id: this.customerId,
          payload: {
            status: 'VERIFIED',
            inVerification: false,
            disapprovalReason: null
          }
        })
        this.$message.success('Profile verification finished. This profile is now Verified.')
        this.$router.push({ name: 'CustomerView', params: { id: this.customerId } })
      } catch {
      } finally {
        this.loading.verificationButtons = false
      }
    },

    async disapprove () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.loading.verificationButtons = true
      try {
        await store.dispatch('users/update', {
          id: this.customerId,
          payload: {
            disapprovalReason: {
              userId: this.customerId,
              text: this.disapprovalReason,
              createdAt: 'CURRENT_TIMESTAMP'
            }
          }
        })
        this.disapprovalReasonDrawer = false
        this.$message.success('Profile verification rejected.')
        this.$router.push({ name: 'CustomerView', params: { id: this.customerId } })
      } catch {
      } finally {
        this.loading.verificationButtons = false
      }
    },

    beforeDisapprove () {
      this.disapprovalReason = null
      this.$v.$reset()
      this.disapprovalReasonDrawer = true
    },

    setPageTitle () {
      const title = 'Customer Verification'
      this.$store.commit('page/setPageInfo', {
        title,
        subtitle: this.customer.uuid,
        breadcrumbs: [
          {
            text: 'Customers',
            to: { name: 'Customers' }
          },
          {
            text: `${this.customer.firstName || ''} ${this.customer.lastName || ''}`,
            to: { name: 'CustomerView', params: { id: this.customerId } }
          },
          {
            text: title
          }
        ]
      })
    }
  },

  validations: {
    disapprovalReason: { required }
  }
}
</script>