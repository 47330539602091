<template>
  <table class="gn-table">
    <thead>
      <tr>
        <th>Offer ID</th>
        <th>Profile ID</th>
        <th>Featured Image</th>
        <th>Distance</th>
        <th>Headline</th>
        <th>Offer Classification</th>
        <th :class="{ 'text-right': !showActions }">Offer Made At</th>
        <th v-if="showActions" class="text-right">Actions</th>
      </tr>
    </thead>
    <tbody>
      <custom-offers-table-row
        v-for="offer in offers"
        :key="offer.id"
        :post="post"
        :offer="offer"
        :show-actions="showActions" />
    </tbody>
  </table>
</template>

<script>

import CustomOffersTableRow from './CustomOffersTableRow'

export default {
  components: {
    CustomOffersTableRow
  },

  props: {
    post: {
      type: Object,
      required: true
    },

    offers: {
      type: Array,
      required: true
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },

    postOwner () {
      return this.user.id === this.post.createdBy.id
    },

    showActions () {
      return this.postOwner || this.offers.some(offer => offer.user.id === this.user.id)
    }
  }
}
</script>
