<template>
  <form-item
    :label="label"
    :validator="validator"
    class="w-full">
    <el-date-picker
      v-if="!showAsText"
      v-model="val"
      v-bind="options"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder || 'Select date'"
      :picker-options="pickerOptions"
      range-separator="To"
      value-format="yyyy-MM-dd"
      start-placeholder="Start date"
      end-placeholder="End date">
    </el-date-picker>
    <span
      v-else
      class="textual">
      {{ val || 'N/A' }}
    </span>
  </form-item>
</template>

<script>
import inputMixin from '../../input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    type: {
      type: String,
      default: 'date'
    },

    pickerOptions: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .textual {
    display: block;
    min-height: 20px;
    line-height: 20px;
    padding: 10px 0;
    font-weight: bold;
  }
</style>