<template>
  <gn-drawer
    v-model="drawer"
    title="Filters"
    destroy-on-close
    @closed="$emit('close')">
    <el-form label-position="top">
      <gn-input
        v-model="form.profileId"
        label="Profile Id"
        placeholder="Filter by Profile ID" />

      <gn-select
        v-model="form.status"
        :options="statusOptions"
        label="Status"
        placeholder="Filter by Status"
        filterable
        clearable />

      <gn-select
        v-model="form.classification"
        :options="classificationsOptions"
        label="Offer Classification"
        placeholder="Filter by Offer Classification"
        clearable />

      <gn-select
        v-model="form.autoPublished"
        :options="yesNoOptions"
        label="Auto-Published"
        placeholder="Filter by Auto-Published"
        clearable />

      <gn-date-picker
        v-model="form.createdAt"
        type="daterange"
        label="Created At">
      </gn-date-picker>

      <gn-select
        v-model="form.awaitingReview"
        :options="yesNoOptions"
        label="Await Review"
        placeholder="Filter by Await Review"
        clearable />

      <gn-date-picker
        v-if="form.awaitingReview === false"
        v-model="form.reviewedAt"
        type="daterange"
        label="Reviewed At">
      </gn-date-picker>
    </el-form>

    <template #footer>
      <el-button
        class="button"
        @click="$emit('close')">
        Cancel
      </el-button>
      <el-button
        class="button"
        type="primary"
        @click="filter()">
        Filter
      </el-button>
    </template>
  </gn-drawer>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: null
    },

    savedOffers: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      form: {
        profileId: null,
        status: null,
        autoPublished: null,
        createdAt: null,
        reviewedAt: null,
        classification: null,
        awaitingReview: null,
      },
      drawer: true,
      classificationsOptions: window.classificationsOptions,
      yesNoOptions: [
        {
          id: true,
          text: 'Yes'
        },
        {
          id: false,
          text: 'No'
        }
      ]
    }
  },

  computed: {
    statusOptions () {
      return this.savedOffers ? window.offerStatusesOptions : window.postOfferStatusesOptions
    }
  },

  created () {
    if (this.filters) {
      this.form = JSON.parse(JSON.stringify(this.filters))
    }
  },

  methods: {
    filter () {
      this.$emit('apply', this.form)
    }
  }
}
</script>

