<template>
  <div>
    <navigation
      v-if="['Posts', 'Offers', 'Trades'].includes($route.name)"
      :menu="menu" />

    <router-view />
  </div>
</template>

<script>
import Navigation from '../components/common/ui/Navigation'

export default {
  components: {
    Navigation
  },

  computed: {
    menu () {
      return [
        {
          name: 'Posts',
          to: { name: 'Posts' }
        },
        {
          name: 'Offers',
          to: { name: 'Offers' }
        },
        {
          name: 'Trades',
          to: { name: 'Trades' }
        }
      ]
    }
  }
}
</script>
