<template>
  <el-table
    :data="offers">
    <el-table-column
      label="Offer ID"
      width="80">
      <template slot-scope="scope">
        <router-link :to="{ name: 'OfferView', params: { id: scope.row.id } }">
          Offer - {{ scope.row.id }}
        </router-link>
      </template>
    </el-table-column>

    <el-table-column
      v-if="!hideProfileId"
      label="Profile ID"
      width="80">
      <template slot-scope="scope">
        <router-link
          :to="{ name: 'CustomerView', params: { id: scope.row.user.id } }"
          target="_blank">
          {{ scope.row.user.uuid }}
        </router-link>
      </template>
    </el-table-column>

    <el-table-column
      v-if="!forPosts"
      label="Pinned to"
      width="80">
      <template slot-scope="scope">
        <router-link
          v-if="scope.row.postOffer"
          :to="{ name: 'PostView', params: { id: scope.row.postOffer.postId } }"
          target="_blank">
          Post - {{ scope.row.postOffer.postId }}
        </router-link>
        <template v-else>
          N/A
        </template>
      </template>
    </el-table-column>

    <el-table-column
      v-if="!forPosts"
      label="Created At"
      width="165">
      <template slot-scope="scope">
        {{ scope.row.createdAt | date }}
      </template>
    </el-table-column>

    <el-table-column
      label="Featured Image"
      width="125">
      <template slot-scope="scope">
        <a
          v-if="scope.row.image"
          :href="`/files/offers/${scope.row.image}`"
          target="_blank">
          <el-image
            :src="`/files/offers/${scope.row.image}`"
            class="featured-image"
            fit="contain">
            <div slot="error" class="image-slot with-icon">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </a>
        <el-image
          v-else
          class="featured-image">
          <div slot="error" class="image-slot">No image</div>          
        </el-image>
      </template>
    </el-table-column>

    <el-table-column label="Distance">
      <template slot-scope="scope">
        {{ getDistanceFromMe(scope.row) | distance }}
      </template>
    </el-table-column>

    <el-table-column label="Headline">
      <template slot-scope="scope">
        <router-link :to="{ name: 'OfferView', params: { id: scope.row.id } }">
          <b>{{ scope.row.headline }}</b>
        </router-link>
      </template>
    </el-table-column>

    <el-table-column
      v-if="!forPosts"
      label="Status"
      width="100">
      <template slot-scope="scope">
        <el-tag
          v-if="scope.row.postOffer"
          :type="postOfferStatusColors[scope.row.postOffer.status]"
          effect="dark"
          size="mini">
          {{ (scope.row.postOffer.status) | postOfferStatus }}
        </el-tag>

        <el-tag
          v-else
          :type="statusColors[scope.row.status]"
          effect="dark"
          size="mini">
          {{ (scope.row.status) | offerStatus }}
        </el-tag>
      </template>
    </el-table-column>

    <el-table-column
      v-if="!forPosts"
      label="Auto-Published"
      width="120">
      <template slot-scope="scope">
        {{ scope.row.autoPublished ? 'Yes' : 'No' }}
      </template>
    </el-table-column>

    <el-table-column
      label="Offer Classification"
      width="140">
      <template slot-scope="scope">
        {{ scope.row.classification | classification }}
      </template>
    </el-table-column>

    <el-table-column
      v-if="!forPosts"
      label="Reviewed At"
      width="165"
      align="right">
      <template slot-scope="scope">
        <template v-if="scope.row.reviewedAt">
          {{ scope.row.reviewedAt | date }}
        </template>
        <template v-else>
          N/A
        </template>
      </template>
    </el-table-column>

    <el-table-column
      v-if="forPosts"
      label="Offer Made At"
      width="165"
      align="right">
      <template slot-scope="scope">
        <template v-if="scope.row.updatedAt">
          {{ scope.row.updatedAt | date }}
        </template>
        <template v-else>
          N/A
        </template>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import helperMixin from '../../common/helper-mixin.js';

export default {
  mixins: [helperMixin],

  props: {
    offers: {
      type: Array,
      required: true
    },

    hideProfileId: {
      type: Boolean,
      default: false
    },

    forPosts: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    statusColors () {
      return window.offerStatusColors
    },

    postOfferStatusColors () {
      return window.postOfferStatusColors
    }
  }
}
</script>

<style lang="scss" scoped>
.featured-image {
  width: 80px;
  height: 80px;
}
</style>
