import Vue from 'vue'

import Panel from '../components/common/ui/Panel'
import Drawer from '../components/common/ui/Drawer'
import Input from '../components/common/ui/forms/Input'
import Select from '../components/common/ui/forms/Select'
import Checkbox from '../components/common/ui/forms/Checkbox'
import Textarea from '../components/common/ui/forms/Textarea'
import DatePicker from '../components/common/ui/forms/DatePicker'
import UploadInput from '../components/common/ui/forms/UploadInput'

Vue.component('panel', Panel)
Vue.component('gn-input', Input)
Vue.component('gn-select', Select)
Vue.component('gn-drawer', Drawer)
Vue.component('gn-checkbox', Checkbox)
Vue.component('gn-textarea', Textarea)
Vue.component('gn-date-picker', DatePicker)
Vue.component('gn-upload-input', UploadInput)