<template>
  <panel :title="title">
    <ul class="documents">
      <li
        v-for="document in documents"
        :key="document.id">
        <span>
          <a :href="document.url" target="_blank">
            {{ document.name }}
          </a>
          <template v-if="document.info">
            ({{ document.info }})
          </template>
        </span>
        <a :href="document.url" target="_blank">
          <el-button
            type="default"
            size="mini"
            round>
            View
          </el-button>
        </a>
      </li>
    </ul>
  </panel>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Documents'
    },

    documents: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.documents {
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 5px;
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
}
</style>