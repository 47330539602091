<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="18">
        <el-alert
          v-if="trade.rejectingReason"
          title="Rejecting reason:"
          type="error"
          class="mb-20"
          :closable="false">
          {{ trade.rejectingReason }}
        </el-alert>

        <panel
          :title="`Post - ${post.headline}`"
          class="post">
          <div class="description">
            <h3>Description:</h3>
            <span v-html="post.description" />
          </div>

          <div class="images">
            <div class="featured-image">
              <img
                v-if="post.image"
                :src="`/files/posts/${post.image}`"
                @click="postPreviewImage = 0" />
            </div>
            <div
              v-if="postExtraImages.length"
              class="extra-images"
              :class="`images-${postExtraImages.length}`">
              <div
                v-for="(image, index) in postExtraImages"
                :key="image + index"
                class="extra-image">
                <img
                  :src="image"
                  @click="postPreviewImage = index + 1" />
              </div>
            </div>
          </div>
        </panel>

        <panel
          :title="`Offer - ${offer.headline}`"
          class="offer">
          <div class="description">
            <h3>Description:</h3>
            <span v-html="offer.description" />
          </div>

          <div class="images">
            <div class="featured-image">
              <img
                v-if="offer.image"
                :src="`/files/offers/${offer.image}`"
                @click="offerPreviewImage = 0" />
            </div>
            <div
              v-if="offerExtraImages.length"
              class="extra-images"
              :class="`images-${offerExtraImages.length}`">
              <div
                v-for="(image, index) in offerExtraImages"
                :key="image + index"
                class="extra-image">
                <img
                  :src="image"
                  @click="offerPreviewImage = index + 1" />
              </div>
            </div>
          </div>
        </panel>
      </el-col>

      <el-col :span="6">
        <panel title="Post Info">
          <template #actions>
            <el-tag
              :type="postStatusColors[post.status]"
              effect="dark"
              size="small">
              {{ post.status | postStatus }}
            </el-tag>
          </template>

          <ul class="gn-list">
            <li>
              <label>Post ID:</label>
              <span>
                <router-link :to="{ name: 'PostView', params: { id: post.id } }">
                  {{ post.id }}
                </router-link>
              </span>
            </li>
            <li>
              <label>Created At:</label>
              <span>{{ post.createdAt | date }}</span>
            </li>
            <li>
              <label>Expiry Date:</label>
              <span>{{ post.expiringAt | date }}</span>
            </li>
            <li>
              <label>Profile:</label>
              <span>
                <router-link :to="{ name: 'CustomerView', params: { id: post.user.id } }">
                  {{ post.user.uuid }} - {{ post.user.firstName }} {{ post.user.lastName }}
                </router-link>
              </span>
            </li>
            <li>
              <label>Auto-Published:</label>
              <span>{{ post.autoPublished ? 'Yes' : 'No' }}</span>
            </li>
            <li>
              <label>Post Classification:</label>
              <span>{{ post.classification | classification }}</span>
            </li>
            <li>
              <label>Reviewed At:</label>
              <span>
                <template v-if="post.reviewedAt">
                  {{ post.reviewedAt | date }}
                </template>
                <template v-else>
                  N/A
                </template>
              </span>
            </li>
            <li>
              <label>Reviewed By:</label>
              <span>
                <template v-if="post.reviewedBy">
                  {{ post.reviewedBy.firstName }} {{ post.reviewedBy.lastName }}
                </template>
                <template v-else>
                  N/A
                </template>
              </span>
            </li>
          </ul>
        </panel>

        <panel title="Offer Info">
          <template #actions>
            <el-tag
              :type="offerStatusColors[offer.status]"
              effect="dark"
              size="small">
              {{ offer.status | offerStatus }}
            </el-tag>
          </template>

          <ul class="gn-list">
            <li>
              <label>Offer ID:</label>
              <span>
                <router-link :to="{ name: 'OfferView', params: { id: offer.id } }">
                  {{ offer.id }}
                </router-link>  
              </span>
            </li>
            <li>
              <label>Created At:</label>
              <span>{{ offer.createdAt | date }}</span>
            </li>
            <li>
              <label>Profile:</label>
              <span>
                <router-link :to="{ name: 'CustomerView', params: { id: offer.user.id } }">
                  {{ offer.user.uuid }} - {{ offer.user.firstName }} {{ offer.user.lastName }}
                </router-link>
              </span>
            </li>
            <li>
              <label>Auto-Published:</label>
              <span>{{ offer.autoPublished ? 'Yes' : 'No' }}</span>
            </li>
            <li>
              <label>Reviewed At:</label>
              <span>
                <template v-if="offer.reviewedAt">
                  {{ offer.reviewedAt | date }}
                </template>
                <template v-else>
                  N/A
                </template>
              </span>
            </li>
            <li>
              <label>Reviewed By:</label>
              <span>
                <template v-if="offer.reviewedBy">
                  {{ offer.reviewedBy.firstName }} {{ offer.reviewedBy.lastName }}
                </template>
                <template v-else>
                  N/A
                </template>
              </span>
            </li>
          </ul>
        </panel>

        <panel title="Trade Info">
          <template #actions>
            <el-tag
              :type="tradeStatusColors[trade.status]"
              effect="dark"
              size="small">
              {{ trade.status | tradeStatus }}
            </el-tag>
          </template>

          <ul class="gn-list">
            <li>
              <label>Trade ID:</label>
              <span>{{ trade.id }}</span>
            </li>
            <li>
              <label>Created At:</label>
              <span>{{ trade.createdAt | date }}</span>
            </li>
            <li>
              <label>Reviewed At:</label>
              <span>
                <template v-if="trade.reviewedAt">
                  {{ trade.reviewedAt | date }}
                </template>
                <template v-else>
                  N/A
                </template>
              </span>
            </li>
            <li>
              <label>Reviewed By:</label>
              <span>
                <template v-if="trade.reviewedBy">
                  {{ trade.reviewedBy.firstName }} {{ trade.reviewedBy.lastName }}
                </template>
                <template v-else>
                  N/A
                </template>
              </span>
            </li>
          </ul>
        </panel>

        <panel v-if="trade.status === 'PENDING' && user.admin">
          <div class="items-center">
            <el-button
              type="success"
              size="small"
              icon="el-icon-check"
              @click="updateStatus('ACCEPTED')">
              Accept
            </el-button>
            <el-button
              type="danger"
              size="small"
              icon="el-icon-close"
              @click="rejectDrawer = true">
              Reject
            </el-button>
          </div>
        </panel>
      </el-col>
    </el-row>

    <rejecting-reason
      v-if="rejectDrawer"
      @close="rejectDrawer = false"
      @reject="updateStatus('REJECTED', $event)" />

    <vue-gallery
      :images="postImages"
      :index="postPreviewImage"
      @close="postPreviewImage = null" />

    <vue-gallery
      :images="offerImages"
      :index="offerPreviewImage"
      @close="offerPreviewImage = null" />
  </div>
</template>

<script>
import store from '../../../store'

import VueGallery from 'vue-gallery'
import RejectingReason from '../posts/RejectingReason'

export default {
  async beforeRouteEnter (to, from, next) {
    await store.dispatch('trades/get', to.params.id)
    next()
  },

  components: {
    VueGallery,
    RejectingReason
  },

  data () {
    return {
      postPreviewImage: null,
      offerPreviewImage: null,
      rejectDrawer: false,
      loading: false
    }
  },

  computed: {
    offerImage () {
      return this.offer.image ? `/files/offers/${this.offer.image}` : null
    },

    postImage () {
      return this.post.image ? `/files/posts/${this.post.image}` : null
    },

    offerExtraImages () {
      const imageKeys = ['image2', 'image3', 'image4', 'image5']
      return imageKeys.map(key => this.offer[key]).filter(image => image).map(image => `/files/offers/${image}`)
    },

    postExtraImages () {
      const imageKeys = ['image2', 'image3', 'image4', 'image5']
      return imageKeys.map(key => this.post[key]).filter(image => image).map(image => `/files/posts/${image}`)
    },

    postImages () {
      return this.postImage ? [this.postImage, ...this.postExtraImages] : this.postExtraImages
    },

    offerImages () {
      return this.offerImage ? [this.offerImage, ...this.offerExtraImages] : this.offerExtraImages
    },

    offerStatusColors () {
      return window.offerStatusColors
    },

    postStatusColors () {
      return window.postStatusColors
    },

    tradeStatusColors () {
      return window.tradeStatusColors
    },

    offer () {
      return this.trade.offer
    },

    post () {
      return this.trade.post
    },

    postOffer () {
      return this.offer.postOffers?.find(item => parseInt(item.postId) === parseInt(this.post.id))
    },

    trade () {
      return this.$store.getters['trades/get'](this.$route.params.id)
    },

    user () {
      return this.$store.state.user.user
    }
  },
  
  created () {
    const title = `Trade - ${this.trade.id}`

    this.$store.commit('page/setPageInfo', {
      title,
      breadcrumbs: [
        {
          text: 'Listings',
          to: { name: 'Posts' }
        },
        {
          text: 'Trades',
          to: { name: 'Trades' }
        },
        {
          text: title
        }
      ]
    })
  },

  methods: {
    async updateStatus (status, rejectingReason = null) {
      this.loading = true
      try {
        this.rejectDrawer = false
        await this.$store.dispatch('trades/update', {
          id: this.trade.id,
          payload: {
            status,
            rejectingReason,
            reviewedAt: 'CURRENT_TIMESTAMP',
            reviewedBy: this.user.id
          }
        })

        await this.$store.dispatch('posts/update', {
          id: this.post.id,
          payload: {
            status: status === 'ACCEPTED' ? 'COMPLETED' : 'ACTIVE'
          }
        })

        await this.$store.dispatch('postOffer/update', {
          id: this.postOffer.id,
          payload: {
            status: status === 'ACCEPTED' ? 'ACCEPTED' : 'REJECTED'
          }
        })

        await this.$store.dispatch('trades/get', this.trade.id)
      } catch {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
