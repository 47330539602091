<template>
  <panel
    :title="title">
    <template #actions>
      <router-link
        v-if="customer.inVerification && user.id === customer.id && customer.disapprovalReason"
        :to="{ name: 'VerifyCustomerProfile', params: { id: customer.id } }">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-edit">
          Edit Verification
        </el-button>
      </router-link>
    </template>
    <el-row
      class="gn-list"
      :gutter="20">
      <el-col
        v-for="(item, index) in data"
        :key="index"
        :sm="12"
        :md="8"
        :lg="6">
        <li>
          <label>{{ item.label }}:</label>
          <span>{{ item.text || 'N/A' }}</span>
        </li>
      </el-col>
    </el-row>
  </panel>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    title: {
      type: String,
      default: 'Verification'
    },

    customer: {
      type: Object,
      required: true
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },

    data () {
      const data = [
        {
          label: 'Profile ID',
          text: this.customer.uuid
        },
        {
          label: 'First Name',
          text: this.customer.firstName
        },
        {
          label: 'Last Name',
          text: this.customer.lastName
        },
        {
          label: 'Email',
          text: this.customer.email
        }
      ]

      if (this.customer.inVerification || this.customer.status === 'VERIFIED') {
        data.push(
          {
            label: 'Sex',
            text: window.sexes[this.customer.sex]
          },
          {
            label: 'Date of Birth',
            text: this.customer.dob ? moment(this.customer.dob).format('LL') : null
          },
          {
            label: 'SSN',
            text: this.customer.ssn
          },
          {
            label: 'Phone',
            text: this.customer.phone
          },
          {
            label: 'Mobile',
            text: this.customer.mobile,
            view: this.customer.mobile
          },
          {
            label: 'Address',
            text: this.customer.address
          },
          {
            label: 'Address 2',
            text: this.customer.address2,
            view: this.customer.address2
          },
          {
            label: 'City',
            text: this.customer.city
          },
          {
            label: 'ZIP / Postal Code',
            text: this.customer.zip
          },
          {
            label: 'State',
            text: window.statesOptions[this.customer.state]
          },
          {
            label: 'Country',
            text: window.countriesOptions[this.customer.country]
          }
        )

        this.customer.identifications.forEach(identification => {
          data.push({
            label: `${window.idTypes[identification.type]} ID Number`,
            text: identification.number
          })
        })
      }

      return data.filter(item => item.view || item.view === undefined)
    }
  }
}
</script>