<template>
  <div>
    <portal to="actions">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="manage = true">
        Add new Change Log
      </el-button>
    </portal>

    <panel title="Change Log">
      <el-table
        :data="changelogs"
        style="width: 100%">
        <el-table-column
          label="Version"
          width="100">
          <template slot-scope="scope">
            {{ scope.row.version }}
          </template>
        </el-table-column>

        <el-table-column label="Description">
          <template slot-scope="scope">
            {{ scope.row.description }}
          </template>
        </el-table-column>

        <el-table-column
          label="Date/Time"
          width="250">
          <template slot-scope="scope">
            {{ scope.row.createdAt | date }}
          </template>
        </el-table-column>

        <el-table-column
          label="Actions"
          width="100"
          align="right">
          <template slot-scope="scope">
            <el-tooltip
              placement="top"
              content="Edit">
              <el-button
                size="mini"
                type="default"
                icon="el-icon-edit"
                circle
                @click="openEdit(scope.row)" />
            </el-tooltip>

            <el-tooltip
              placement="top"
              content="Delete">
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="remove(scope.row.id)" />
            </el-tooltip>

          </template>
        </el-table-column>
      </el-table>
    </panel>

    <gn-drawer
      v-model="manage"
      title="Add new Change Log"
      :before-close="beforeClose"
      @closed="onClose()">
      <el-form label-position="top">
        <gn-input
          v-model="form.version"
          :validator="$v.form.version"
          label="Version"
          placeholder="Enter Version" />

        <gn-textarea
          v-model="form.description"
          :validator="$v.form.description"
          label="Description"
          placeholder="Enter description" />
      </el-form>

      <template #footer>
        <el-button
          class="button"
          @click="manage = false">
          Cancel
        </el-button>
        <el-button
          class="button"
          type="primary"
          :loading="loading"
          @click="save()">
          {{ loading ? 'Saving...' : 'Save' }}
        </el-button>
      </template>
    </gn-drawer>
  </div>
</template>

<script>
import store from '../../store'
import { required } from 'vuelidate/lib/validators'

export default {
  async beforeRouteEnter (to, from, next) {
    await store.dispatch('changeLog/load')
    next()
  },

  data () {
    return {
      form: {
        version: null,
        description: null
      },
      manage: false,
      editing: null,
      loading: false
    }
  },

  computed: {
    changelogs () {
      return this.$store.getters['changeLog/list'] || []
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Change Log',
      breadcrumbs: [
        {
          text: 'Settings'
        },
        {
          text: 'Change Log'
        }
      ]
    })
  },

  methods: {
    async save () {
      this.loading = true
      try {
        const payload = this.editing
          ? { id: this.editing.id, payload: this.form }
          : { ...this.form, createdAt: 'CURRENT_TIMESTAMP' }

        await this.$store.dispatch(`changeLog/${this.editing ? 'update' : 'create'}`, payload)

        this.$message.success(`Change Log successfully ${this.editing ? 'edited' : 'created'}.`)
        this.onClose()
      } catch (e) {
        console.error(e)
      }
    },

    async remove(id) {
      try {
        await this.$confirm('Are you sure to delete Change Log?')
        await this.$store.dispatch('changeLog/delete', id)
      } catch (e) {
        console.error(e)
      }
    },

    openEdit (changelog) {
      this.editing = changelog
      this.form.version = changelog.version
      this.form.description = changelog.description
      this.manage = true
    },

    async beforeClose (done) {
      if (!this.form.version && !this.form.description) return done()
      try {
        await this.$confirm('Are you sure you want to close this?')
        done()
      } catch (e) {
        console.error(e)
      }
    },

    onClose () {
      this.loading = false
      this.manage = false
      this.$v.form.$reset()
      this.editing = null
      this.form.message = null
    }
  },

  validations: {
    form: {
      version: { required },
      description: { required }
    }
  }
}
</script>
