import Vue from 'vue'
import axios from 'axios'

export default entity => {
  const state = {
    data: null,
    singles: {}
  }
  
  const getters = {
    get: state => id => state.singles[id],
    list: state => Object.values(state.singles)
  }

  const actions = {
    async load ({ commit }, payload) {
      const response = await axios.get(entity, payload)
      commit('set', response.data.items)
      response.data.items.forEach(item => commit('setSingle', item))
      return response.data.items
    },

    async get ({ commit }, id) {
      const response = await axios.get(`${entity}/${id}`)
      commit('setSingle', response.data.item)
      return response.data.item
    },

    async create ({ commit }, payload) {
      const response = await axios.post(entity, {
        obj: payload,
        returnObj: true
      })
      commit('add', response.data.item)
      commit('setSingle', response.data.item)
      return response.data.item
    },
  
    async update ({ commit }, { id, payload }) {
      const response = await axios.put(`${entity}/${id}`, {
        obj: payload,
        returnObj: true
      })
      commit('update', response.data.item)
      commit('setSingle', response.data.item)
      return response.data.item
    },
  
    async delete ({ commit }, id) {
      await axios.delete(`${entity}/${id}`)
      return commit('delete', id)
    },
  
    async upload ({ commit }, { id, file, params = null }) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('returnObj', true)
      
      if (params) {
        for(let field in params) formData.append(field, params[field])
      }

      const response = await axios.post(`${entity}/${id}`, formData)
      commit('update', response.data.item)
      commit('setSingle', response.data.item)
      return response.data.item
    },

    async action ({ commit }, { id, action, payload = null }) {
      const response = await axios.post(`${entity}/${id}/${action}`, payload)
      commit('update', response.data.item)
      commit('setSingle', response.data.item)
      return response.data
    },
  }
  
  const mutations = {
    add (state, data) {
      if (state.data) {
        state.data.push(data)
      } else {
        state.data = [data]
      }
    },

    set (state, data) {
      state.data = data
    },

    update (state, data) {
      if (state.data) {
        const idx = state.data.findIndex(item => item.id == data.id)
        Vue.set(state.data, idx, data)
      }
    },

    setSingle (state, data) {
      Vue.set(state.singles, data.id, data)
    },

    delete (state, id) {
      Vue.delete(state.singles, id)

      if (state.data) {
        const idx = state.data.findIndex(item => item.id === id)
        if (idx >= 0) state.data.splice(idx, 1)
      }
    }
  }

  return {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
  }
}