<template>
  <div />
</template>

<script>
export default {
  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Dashboard',
      breadcrumbs: [
        {
          text: 'Dashboard'
        }
      ]
    })
  }
}
</script>