<template>
  <gn-drawer
    v-model="drawer"
    title="Filters"
    :before-close="() => (drawer = false)"
    @closed="$emit('close')">
    <el-form label-position="top">
      <gn-date-picker
        v-model="form.createdAt"
        type="daterange"
        label="Created At">
      </gn-date-picker>

      <gn-input
        v-model="form.uuid"
        label="Profile Id"
        placeholder="Filter by Profile ID" />

      <gn-input
        v-model="form.firstName"
        label="First Name"
        placeholder="Filter by first name" />

      <gn-input
        v-model="form.lastName"
        label="Last Name"
        placeholder="Filter by last name" />

      <gn-input
        v-model="form.email"
        label="Email"
        placeholder="Filter by email" />

      <gn-select
        v-model="form.status"
        :options="statusOptions"
        label="Status"
        placeholder="Filter by Status"
        filterable
        clearable />

      <gn-select
        v-model="form.verified"
        :options="verifiedOptions"
        label="Verified"
        placeholder="Filter by Verified"
        filterable
        clearable />
    </el-form>

    <template #footer>
      <el-button
        class="button"
        @click="$emit('close')">
        Cancel
      </el-button>
      <el-button
        class="button"
        type="primary"
        @click="filter()">
        Filter
      </el-button>
    </template>
  </gn-drawer>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      form: {
        uuid: null,
        status: null,
        verified: null,
        firstName: null,
        lastName: null,
        email: null,
        createdAt: null
      },
      drawer: true,
      statusOptions: window.userStatusesOptions,
      verifiedOptions: window.customersVerifiedOptions
    }
  },

  created () {
    if (this.filters) {
      this.form = JSON.parse(JSON.stringify(this.filters))
    }
  },

  methods: {
    filter () {
      this.$emit('apply', this.form)
    }
  }
}
</script>
