<template>
  <gn-drawer
    v-model="drawer"
    :title="edit ? 'Edit Administrator' : 'Add new Administrator'"
    :before-close="beforeClose"
    @closed="$emit('close')">
    <el-form label-position="top">
      <div
        v-if="error"
        class="textual-error">
        {{ error }}
      </div>

      <gn-input
        v-model="form.firstName"
        :validator="$v.form.firstName"
        label="First Name"
        placeholder="Enter first name" />

      <gn-input
        v-model="form.lastName"
        :validator="$v.form.lastName"
        label="Last Name"
        placeholder="Enter last name" />

      <gn-input
        v-model="form.email"
        :validator="$v.form.email"
        label="Email"
        placeholder="Enter email" />
    </el-form>

    <template #footer>
      <el-button
        class="button"
        @click="$emit('close')">
        Cancel
      </el-button>
      <el-button
        class="button"
        type="primary"
        :loading="loading"
        @click="save()">
        {{ loading ? 'Saving...' : 'Save' }}
      </el-button>
    </template>
  </gn-drawer>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  props: {
    edit: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        email: null
      },
      loading: false,
      drawer: true,
      error: null
    }
  },

  created () {
    if (this.edit) {
      this.form.firstName = this.edit.firstName
      this.form.lastName = this.edit.lastName
      this.form.email = this.edit.email
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        const payload = this.edit ? { id: this.edit.id, payload: this.form } : {
          ...this.form,
          status: 'REGISTERED',
          admin: true,
          createdBy: window.appData.currentUser.id
        }

        const action = this.edit ? 'users/update' : 'users/create'
        const user = await this.$store.dispatch(action, payload)
        if (!this.edit) {
          await this.$store.dispatch('users/action', { id: user.id, action: 'GenerateUuid' })
          await this.$store.dispatch('users/action', { id: user.id, action: 'SendEmployeeRegistrationEmail' })
        }
        this.$message.success(`Administrator successfully ${this.edit ? 'updated' : 'created'}.`)
        this.clear()
        this.$emit('close')
      } catch (error) {
        this.error = error.response.data.error
      } finally {
        this.loading = false
      }
    },

    async beforeClose (done) {
      if (Object.values(this.form).every(val => !val)) return done()

      try {
        await this.$confirm('Are you sure you want to close this?')
        done()
      } catch (e) {
        console.error(e)
      }
    },

    clear () {
      this.form = {
        firstName: null,
        lastName: null,
        email: null,
      }
      this.$v.$reset()
    }
  },

  validations () {
    const rules = {
      form: {
        firstName: { required },
        lastName: { required },
        email: { required, email }
      }
    }

    return rules
  }
}
</script>
