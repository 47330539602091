<template>
  <image-panel
    title="Give and Get"
    subtitle="Stuff exchange platform."
    image="assets/images/teddy_bear.svg"
    :right="right"
    footer>
    <section class="content-wrapper">
      <img
        :src="logo"
        class="logo">

      <h1 class="gn-title">{{ title }}</h1>
      <h2 class="gn-subtitle">{{ subtitle }}</h2>

      <div class="wrapper">
        <slot />
      </div>
    </section>
  </image-panel>
</template>

<script>
import defaultLogo from '../../images/logo.png'
import ImagePanel from '../components/common/ui/ImagePanel'

export default {
  components: {
    ImagePanel
  },

  props: {
    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      required: true
    },

    right: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    system () {
      return this.$store.getters['system/get'](1)
    },
    
    logo () {
      return this.system.logo ? `/files/system/${this.system.logo}` : defaultLogo
    }
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  h1, h2 {
    text-align: center;
  }
  .wrapper {
    padding-top: 30px;
  }
  .logo {
    display: block;
    height: 50px;
    margin: 0 auto 40px auto;
  }
}
</style>