<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="18">
        <el-alert
          v-if="offer.rejectingReason"
          title="Rejecting reason:"
          type="error"
          class="mb-20"
          :closable="false">
          {{ offer.rejectingReason }}
        </el-alert>

        <panel
          :title="offer.headline"
          class="offer">
          <div class="description">
            <h3>Description:</h3>
            <span v-html="offer.description" />
          </div>

          <div class="images">
            <div class="featured-image">
              <img
                v-if="offer.image"
                :src="`/files/offers/${offer.image}`"
                @click="previewImage = 0" />
            </div>
            <div
              v-if="extraImages.length"
              class="extra-images"
              :class="`images-${extraImages.length}`">
              <div
                v-for="(image, index) in extraImages"
                :key="image + index"
                class="extra-image">
                <img
                  :src="image"
                  @click="previewImage = index + 1" />
              </div>
            </div>
          </div>
        </panel>
      </el-col>

      <el-col :span="6">
        <panel title="Offer Info">
          <template #actions>
            <el-tag
              :type="statusColors[offer.status]"
              effect="dark"
              size="small">
              {{ offer.status | offerStatus }}
            </el-tag>
          </template>

          <ul class="gn-list">
            <li>
              <label>Offer ID:</label>
              <span>{{ offer.id }}</span>
            </li>
            <li>
              <label>Created At:</label>
              <span>{{ offer.createdAt | date }}</span>
            </li>
            <li>
              <label>Profile:</label>
              <span>
                <router-link :to="{ name: 'CustomerView', params: { id: offer.user.id } }">
                  {{ offer.user.uuid }} - {{ offer.user.firstName }} {{ offer.user.lastName }}
                </router-link>
              </span>
            </li>
            <li>
              <label>Auto-Published:</label>
              <span>{{ offer.autoPublished ? 'Yes' : 'No' }}</span>
            </li>
            <li>
              <label>Reviewed At:</label>
              <span>
                <template v-if="offer.reviewedAt">
                  {{ offer.reviewedAt | date }}
                </template>
                <template v-else>
                  N/A
                </template>
              </span>
            </li>
            <li>
              <label>Reviewed By:</label>
              <span>
                <template v-if="offer.reviewedBy">
                  {{ offer.reviewedBy.firstName }} {{ offer.reviewedBy.lastName }}
                </template>
                <template v-else>
                  N/A
                </template>
              </span>
            </li>
          </ul>
        </panel>

        <panel v-if="!offer.reviewedAt">
          <div class="items-center">
            <el-button
              type="success"
              size="small"
              icon="el-icon-check"
              @click="updateStatus('ACTIVE')">
              Approve
            </el-button>
            <el-button
              type="danger"
              size="small"
              icon="el-icon-close"
              @click="rejectDrawer = true">
              Reject
            </el-button>
          </div>
        </panel>
      </el-col>
    </el-row>

    <rejecting-reason
      v-if="rejectDrawer"
      @close="rejectDrawer = false"
      @reject="updateStatus('REJECTED', $event)" />

    <vue-gallery
      :images="images"
      :index="previewImage"
      @close="previewImage = null" />
  </div>
</template>

<script>
import store from '../../../store'

import VueGallery from 'vue-gallery'
import RejectingReason from '../posts/RejectingReason'

export default {
  async beforeRouteEnter (to, from, next) {
    const offer = await store.dispatch('offers/get', to.params.id)
    next()
  },

  components: {
    VueGallery,
    RejectingReason
  },

  data () {
    return {
      previewImage: null,
      rejectDrawer: false,
      loading: false
    }
  },

  computed: {
    image () {
      return this.offer.image ? `/files/offers/${this.offer.image}` : null
    },

    extraImages () {
      const imageKeys = ['image2', 'image3', 'image4', 'image5']
      return imageKeys.map(key => this.offer[key]).filter(image => image).map(image => `/files/offers/${image}`)
    },

    images () {
      return this.image ? [this.image, ...this.extraImages] : this.extraImages
    },

    statusColors () {
      return window.offerStatusColors
    },

    offer () {
      return this.$store.getters['offers/get'](this.$route.params.id)
    },

    postId () {
      return this.$route.query.postId || null
    },

    user () {
      return this.$store.state.user.user
    }
  },
  
  created () {
    const title = this.offer.headline

    const middleBreadcrumbs = this.postId ? [
      {
        text: 'Posts',
        to: { name: 'Posts' }
      },
      {
        text: `Post - ${this.postId}`,
        to: { name: 'PostView', params: { id: this.postId } }
      }
    ] : [
      {
        text: 'Offers',
        to: { name: 'Offers' }
      },
    ]


    this.$store.commit('page/setPageInfo', {
      title,
      breadcrumbs: [
        {
          text: 'Listings',
          to: { name: 'Posts' }
        },
        ...middleBreadcrumbs,
        {
          text: `Offer - ${this.offer.id}`
        }
      ]
    })
  },

  methods: {
    async updateStatus (status, rejectingReason = null) {
      this.loading = true
      try {
        this.rejectDrawer = false
        await this.$store.dispatch('offers/update', {
          id: this.offer.id,
          payload: {
            status,
            rejectingReason,
            reviewedAt: 'CURRENT_TIMESTAMP',
            reviewedBy: this.user.id
          }
        })
      } catch {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
