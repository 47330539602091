<template>
  <gn-drawer
    v-model="drawer"
    title="Rejecting reason"
    :before-close="beforeClose"
    :size="500"
    @closed="$emit('close')">
    <el-form label-position="top">
      <gn-textarea
        v-model="rejectingReason"
        :validator="$v.rejectingReason"
        label="Rejecting reason"
        placeholder="Enter Rejecting reason" />
    </el-form>

    <template #footer>
      <el-button
        class="button"
        @click="$emit('close')">
        Cancel
      </el-button>
      <el-button
        class="button"
        type="danger"
        icon="el-icon-close"
        @click="$emit('reject', rejectingReason)">
        Reject
      </el-button>
    </template>
  </gn-drawer>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      drawer: true,
      rejectingReason: null
    }
  },

  methods: {
    async beforeClose (done) {
      if (!this.rejectingReason) return done()

      try {
        await this.$confirm('Are you sure you want to close this?')
        done()
      } catch (e) {
        console.error(e)
      }
    }
  },

  validations: {
    rejectingReason: { required }
  }
}
</script>