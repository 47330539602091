<template>
  <tr>
    <td>
      <router-link :to="offerUrl">
        Offer - {{ offer.id }}
      </router-link>
    </td>

    <td>
      <router-link
        :to="{ name: 'CustomerView', params: { id: offer.user.id } }"
        target="_blank">
        {{ offer.user.uuid }}
      </router-link>
    </td>

    <td>
      <a
        v-if="offer.image"
        :href="`/files/offers/${offer.image}`"
        target="_blank">
        <el-image
          :src="`/files/offers/${offer.image}`"
          class="featured-image"
          fit="contain">
          <div slot="error" class="image-slot with-icon">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </a>
      <el-image
        v-else
        class="featured-image">
        <div slot="error" class="image-slot">No image</div>          
      </el-image>
    </td>

    <td>
      {{ getDistanceFromMe(offer) | distance }}
    </td>

    <td>
      <router-link :to="offerUrl">
        <b>{{ offer.headline }}</b>
      </router-link>
    </td>

    <td>
      {{ offer.classification | classification }}
    </td>

    <td :class="{ 'text-right': !showActions }">
      <template v-if="postOffer.createdAt">
        {{ postOffer.createdAt | date }}
      </template>
      <template v-else>
        N/A
      </template>
    </td>

    <td v-if="showActions" class="text-right">
      <template v-if="offer.status === 'ACTIVE' && postOffer.status === 'ACTIVE'">
        <template v-if="hasPermissions">
          <el-button
            type="success"
            size="mini"
            icon="el-icon-check"
            @click="askAccept()">
            Accept
          </el-button>
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-close"
            @click="deny()">
            Deny
          </el-button>
        </template>
        <el-button
          v-if="offer.user.id === user.id && postOffer.status === 'PENDING'"
          type="default"
          size="mini"
          icon="el-icon-close"
          @click="cancel()">
          Cancel
        </el-button>
      </template>
    </td>

    <td
      v-if="offer.status === 'PENDING'"
      class="tr-overlay">
      <router-link v-if="user.admin" :to="{ name: 'OfferView', params: { id: offer.id } }" target="_blank">
        <span>This offer is awaiting approval.</span>
      </router-link>
      <span v-else>This offer is awaiting approval.</span>
    </td>
  </tr>
</template>

<script>
import helperMixin from '../../common/helper-mixin'

export default {
  mixins: [helperMixin],

  props: {
    post: {
      type: Object,
      required: true
    },

    offer: {
      type: Object,
      required: true
    },

    showActions: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    system () {
      return this.$store.getters['system/get'](1)
    },

    user () {
      return this.$store.state.user.user
    },

    
    hasPermissions () {
      return this.user.admin || this.postOwner || this.user.id === this.post.user.id
    },

    postOwner () {
      return this.user.id === this.post.createdBy.id
    },

    offerUrl () {
      return { name: 'OfferView', params: { id: this.offer.id }, query: { postId: this.post.id } }
    },

    postOffer () {
      return this.offer.postOffers.find(postOffer => postOffer.postId === this.post.id)
    }
  },

  methods: {
    async accept () {
      const postOffers = await this.$store.dispatch('postOffer/load')
      const promises = postOffers
        .filter(postOffer => postOffer.postId === this.post.id)
        .map(postOffer => this.updateStatus(postOffer.id, postOffer.offerId === this.offer.id ? 'ACCEPTED' : 'DENIED'))
      
      return Promise.all(promises)
    },

    async askAccept () {
      try {
        await this.$confirm('Are you sure you want to accept this offer?')
        await this.accept()
        
        const statuses = {
          'YES': 'ACCEPTED',
          'NO': 'PENDING'
        }
        const status = statuses[this.system.trade_auto_push]
        await this.$store.dispatch('trades/create', {
          postId: this.post.id,
          offerId: this.offer.id,
          status,
          createdAt: 'CURRENT_TIMESTAMP'
        })

        await this.$store.dispatch('posts/update', {
          id: this.post.id,
          payload: {
            status: status === 'PENDING' ? 'PENDING_TRADE' : 'COMPLETED'
          }
        })
        await this.updateStatus(this.postOffer.id, status === 'PENDING' ? 'PENDING_TRADE' : 'ACCEPTED')

        await this.reloadOffers()
        this.$message.success('Offer accepted.')
      } catch (e) {
        console.error(e)
      }
    },

    async deny () {
      try {
        await this.$confirm('Are you sure you want to deny this offer?')
        await this.updateStatus(this.postOffer.id, 'DENIED')
        await this.reloadOffers()
        this.$message.success('Offer denied.')
      } catch (e) {
        console.error(e)
      }
      
    },

    async cancel () {
      try {
        await this.$confirm('Are you sure you want to cancel offer?')
        await this.updateStatus(this.postOffer.id, 'CANCELED')
        await this.reloadOffers()
        this.$message.success('Offer canceled.')
      } catch (e) {
        console.error(e)
      }
    },

    updateStatus (id, status) {
      return this.$store.dispatch('postOffer/update', {
        id,
        payload: {
          status,
          reviewedAt: 'CURRENT_TIMESTAMP'
        }
      })
    },

    reloadOffers () {
      return this.$store.dispatch('offers/load')
    }
  }
}
</script>

<style lang="scss" scoped>
.featured-image {
  width: 80px;
  height: 80px;
}
</style>
